import React from 'react';
import './SocialMedia.css';
import SectionHeader from '../SectionHeader/SectionHeader';

const SocialMedia = () => {
  return (
    <div className="SocialMedia__container">
      <SectionHeader title="Social Media" />
    </div>
  );
};

export default SocialMedia;
