import React from 'react';
import AboutExperience from './AboutExperience';
import AboutIntro from './AboutIntro';

const About = () => {
  return (
    // <div className="About__container">
    <>
      <AboutIntro />
      <AboutExperience />
    </>
    // </div>
  );
};

export default About;
